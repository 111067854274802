import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { io } from "socket.io-client";
import {
  Box,
  Typography,
  TextField,
  Button,
  Container,
  useTheme,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  InputAdornment,
  Modal,
  Tooltip,
  Stack,
} from "@mui/material";
import jsPDF from "jspdf";
import {
  fetchInventoryByPhone,
  getFullInventory,
  saveInventory,
  sendInventoryText,
} from "../../redux/actions/room-details";
import { isConstructorDeclaration } from "typescript";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
const InventoryPopulate = () => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [generatedUrl, setGeneratedUrl] = useState("");
  const [inventoryData, setInventoryData] = useState<any[]>([]);
  const dispatch = useDispatch();
  const [error, setError] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const thisUrl = process.env.REACT_APP_API_URL || "";
  const theme = useTheme();
  const siteUrl = process.env.REACT_APP_URL || "";
  const [inventoryOptions, setInventoryOptions] = useState<any[]>([]);
  const [selectedInventory, setSelectedInventory] = useState<any[]>([]);
  const [deletedItems, setDeletedItems] = useState<any[]>([]);
  const [showSaveButton, setShowSaveButton] = useState(false);
  const [allowSocketUpdate, setAllowSocketUpdate] = useState(true);
  const [sessionId, setSessionId] = useState<string>("");
  const [successMessage, setSuccessMessage] = useState("");

  useEffect(() => {
    const socket = io(thisUrl);
    socket.on("connect", () => {
      console.log("Connected to WebSocket server");
    });

    socket.on("connect_error", (error) => {
      console.error("WebSocket connection error:", error);
    });

    socket.on("showInventoryResponse", (phoneResponse: any) => {
      if (!allowSocketUpdate) return;
      const { phoneNumber, currentCount, totalCount, sessionId } =
        phoneResponse;
      if (currentCount === totalCount) {
        fetchInventoryData(phoneNumber);
      }
      handleGetInventory(phoneNumber);
      setSessionId(sessionId);
    });

    return () => {
      socket.disconnect();
    };
  }, [phoneNumber, allowSocketUpdate]);
  const fetchInventoryData = async (phone: string) => {
    try {
      const res = await dispatch<any>(fetchInventoryByPhone({ phone })).then(
        unwrapResult
      );
      setInventoryData(res.inventory);
    } catch (error) {
      console.error("Error fetching inventory:", error);
      setError("unable to fetch inventory data.");
      setModalOpen(true);
    }
  };

  const handleGetInventory = async (phone: string) => {
    if (phoneNumber.trim() === "") {
      setError("Please enter a valid phone number");
      setModalOpen(true);
      return;
    }

    if (phoneNumber.length < 10) {
      setError("Phone number must be 10 digits");
      setModalOpen(true);
      return;
    }
    try {
      const res = await dispatch<any>(getFullInventory({ phone })).then(
        unwrapResult
      );
      if (res.inventory && Array.isArray(res.inventory)) {
      } else {
        console.error("res.inventory is not defined or is not an array");
      }
      if (res.inventory.length > 0) {
        setInventoryOptions(res.inventory);
        setSelectedInventory([]);
      }
    } catch (error) {
      console.error("Error fetching inventory:", error);
      setError("unable to fetch inventory data.");
      setModalOpen(true);
    }
  };
  const handleSelectInventory = (inventory: any) => {
    if (inventory && inventory.inventory) {
      setSelectedInventory(inventory.inventory);
      setSessionId(inventory.sessionId);
    } else {
      setSelectedInventory([]);
      setSessionId("");
    }
  };
  const handleGenerateUrl = () => {
    if (phoneNumber.trim() === "") {
      setError("Please enter a valid phone number");
      setModalOpen(true);
      return;
    }
    if (phoneNumber.length < 10) {
      setError("Phone number must be 10 digits");
      setModalOpen(true);
      return;
    }
    const baseURL = `${window.location.protocol}//${window.location.host}`;
    console.log(baseURL);
    setGeneratedUrl(`${baseURL}/add-inventory/${phoneNumber}`);
  };
  const handleInputPhoneNumber = (event: any) => {
    let inputValue = event.target.value.replace(/[^0-9]/g, "");
    const maxLength = 10;

    if (inputValue.length > maxLength) {
      inputValue = inputValue.substring(0, maxLength);
    }

    setPhoneNumber(inputValue);
    setError(inputValue.length < 10 ? "Phone number must be 10 digits" : "");

    if (inputValue === "") {
      setGeneratedUrl("");
    }
  };

  const [copied, setCopied] = useState(false);

  const handleCopyUrl = () => {
    navigator.clipboard.writeText(generatedUrl);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };
  const handleSendText = async () => {
    const body = { phone: phoneNumber };
    try {
      const res = await dispatch<any>(sendInventoryText(body))
        .then(unwrapResult)
        .then((res: any) => {
          alert("Success! URL has been successfully sent to the customer.");
        });
    } catch (error) {
      console.error("Error fetching inventory:", error);
      setError("unable to send message");
      setModalOpen(true);
    }
  };
  const getVolume = (item: any) => {
    const dimensions = item.predicted_dimensions || [];
    const length = dimensions[0] ? parseFloat(dimensions[0]) : 0;
    const width = dimensions[1] ? parseFloat(dimensions[1]) : 0;
    const height = dimensions[2] ? parseFloat(dimensions[2]) : 0;
    const itemVolume = length * width * height;
    const totalCountVolume = itemVolume * item.count;
    return totalCountVolume;
  };

  const getWeightInKg = (item: any) => {
    const volume = getVolume(item);
    return volume * 7 * 0.453592;
  };

  const getWeightInPounds = (item: any) => {
    const volume = getVolume(item);
    return volume * 7;
  };

  const calculateTotals = (data: any[]) => {
    let totalVolume = 0;
    let totalWeightKg = 0;
    let totalWeightPounds = 0;

    data.forEach((entry) => {
      const inventory = entry.inventory ? entry.inventory : data;

      inventory.forEach((room: any) => {
        if (room.images && Array.isArray(room.images)) {
          room.images.forEach((image: any) => {
            if (image.items && Array.isArray(image.items)) {
              image.items.forEach((item: any) => {
                const itemVolume = getVolume(item);
                const itemWeightKg = getWeightInKg(item);
                const itemWeightPounds = getWeightInPounds(item);

                totalVolume += itemVolume;
                totalWeightKg += itemWeightKg;
                totalWeightPounds += itemWeightPounds;
              });
            }
          });
        }
      });
    });

    return { totalVolume, totalWeightKg, totalWeightPounds };
  };

  const { totalVolume, totalWeightKg, totalWeightPounds } =
    selectedInventory.length > 0
      ? calculateTotals([{ inventory: selectedInventory }])
      : calculateTotals(inventoryData);

  const handleDownloadPDF = async () => {
    const doc = new jsPDF();
    const pageWidth = doc.internal.pageSize.getWidth();
    const margin = 10;
    const maxWidth = pageWidth - margin * 2;
    const lineHeight = 10;

    const dataToPrint =
      selectedInventory.length > 0
        ? selectedInventory
        : inventoryData.flatMap((i) => i.inventory);

    doc.text("Inventory Report", 10, 10);

    let yPosition = 20;
    const pageHeight = doc.internal.pageSize.height;

    for (const room of dataToPrint) {
      doc.text(`Room: ${room.roomName}`, 10, yPosition);
      yPosition += 10;

      for (const image of room.images) {
        if (yPosition > pageHeight - 30) {
          doc.addPage();
          yPosition = 20;
        }

        doc.text("Image URL:", margin, yPosition);
        yPosition += lineHeight;

        const splitUrl = doc.splitTextToSize(image.imageUrl, maxWidth);
        splitUrl.forEach((line: any, index: any) => {
          doc.textWithLink(line, margin, yPosition, { url: image.imageUrl });
          yPosition += lineHeight;
        });

        for (const item of image.items) {
          if (yPosition > pageHeight - 30) {
            doc.addPage();
            yPosition = 20;
          }

          doc.text(`Object Name: ${item.object_name}`, 10, yPosition);
          yPosition += 10;
          doc.text(`Count: ${item.count}`, 10, yPosition);
          yPosition += 10;
          doc.text(`Bulky: ${item.bulky ? "Yes" : "No"}`, 10, yPosition);
          yPosition += 10;
          doc.text(
            `Cubic Feet: ${
              item.predicted_dimensions
                ? Math.ceil(
                    item.predicted_dimensions.reduce(
                      (acc: number, val: number) => acc * val,
                      1
                    )
                  )
                : "N/A"
            }`,
            10,
            yPosition
          );
          yPosition += 15;
        }
      }
    }

    if (yPosition > pageHeight - 30) {
      doc.addPage();
      yPosition = 20;
    }

    const { totalVolume, totalWeightKg, totalWeightPounds } =
      selectedInventory.length > 0
        ? calculateTotals([{ inventory: selectedInventory }])
        : calculateTotals(inventoryData);

    doc.text("Calculated Volume and Weights:", 10, yPosition);
    yPosition += 10;
    doc.text(
      `Total Volume (Cubic Feet): ${Math.ceil(Number(totalVolume.toFixed(3)))}`,
      10,
      yPosition
    );
    yPosition += 10;
    doc.text(
      `Total Weight (Pounds): ${Math.ceil(Number(totalWeightPounds.toFixed(3)))}`,
      10,
      yPosition
    );
    yPosition += 10;
    doc.text(
      `Total Weight (Kilograms): ${Math.ceil(Number(totalWeightKg.toFixed(3)))}`,
      10,
      yPosition
    );

    doc.save("inventory.pdf");
  };
  const handleDeleteItem = (roomName: any, imageUrl: any, item: any) => {
    setAllowSocketUpdate(false);
    if (inventoryData.length > 0) {
      const updatedInventoryData = inventoryData
        .map((inventoryItem) => {
          const updatedRooms = inventoryItem.inventory
            .map((room: any) => {
              if (room.roomName === roomName) {
                const updatedImages = room.images
                  .map((image: any) => {
                    if (image.imageUrl === imageUrl) {
                      const updatedItems = image.items.filter(
                        (i: any) => !(i.object_name === item.object_name)
                      );
                      return { ...image, items: updatedItems };
                    }
                    return image;
                  })
                  .filter((image: any) => image.items.length > 0);
                return { ...room, images: updatedImages };
              }
              return room;
            })
            .filter((room: any) => room.images.length > 0);
          return { ...inventoryItem, inventory: updatedRooms };
        })
        .filter((inventoryItem: any) => inventoryItem.inventory.length > 0);

      setInventoryData(updatedInventoryData);
    }
    setSelectedInventory((prev) =>
      prev
        .map((room) => ({
          ...room,
          images: room.images
            .map((image: any) => ({
              ...image,
              items: image.items.filter(
                (i: any) =>
                  !(
                    i.object_name === item.object_name &&
                    image.imageUrl === imageUrl
                  )
              ),
            }))
            .filter((image: any) => image.items.length > 0),
        }))
        .filter((room) => room.images.length > 0)
    );
    setDeletedItems((prev) => [...prev, { roomName, imageUrl, item }]);
    setShowSaveButton(true);
  };
  const handleSaveInventory = async () => {
    setAllowSocketUpdate(true);
    const dataToSave =
      selectedInventory.length > 0 ? selectedInventory : inventoryData;
    const updatedInventory = dataToSave
      .map((item) => {
        const rooms = item.inventory ? item.inventory : [item];
        return rooms.map((room: any) => ({
          roomName: room.roomName,
          images: room.images.map((image: any) => ({
            imageUrl: image.imageUrl,
            items: image.items.map((item: any) => ({
              object_name: item.object_name,
              count: item.count,
              bulky: item.bulky,
              predicted_dimensions: item.predicted_dimensions,
            })),
          })),
        }));
      })
      .flat();
    try {
      const res = await dispatch<any>(
        saveInventory({ sessionId, inventory: updatedInventory })
      ).then(unwrapResult);
      if (res.success) {
        setSuccessMessage("Inventory saved successfully!");
        setModalOpen(true);
        setDeletedItems([]);
        setShowSaveButton(false);
      }
    } catch (error) {
      console.error("Error saving inventory:", error);
      setError("Unable to save inventory data.");
      setModalOpen(true);
    }
  };

  return (
    <Container maxWidth="lg" sx={{ textAlign: "center", mt: 4 }}>
      <Typography
        variant="h4"
        component="h1"
        gutterBottom
        sx={{ fontWeight: "bold" }}
      >
        Inventory Populate
      </Typography>

      <Box mt={2}>
        <Typography variant="subtitle1" sx={{ mb: 1 }}>
          Enter Phone Number:
        </Typography>
        <TextField
          value={phoneNumber}
          onChange={handleInputPhoneNumber}
          placeholder="Enter phone number"
          fullWidth
          variant="outlined"
          error={!!error}
          inputProps={{
            inputMode: "numeric",
            pattern: "[0-9]*",
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">+1</InputAdornment>
            ),
          }}
        />
      </Box>

      <Box mt={3}>
        <Button
          onClick={handleGenerateUrl}
          variant="contained"
          color="primary"
          fullWidth
          sx={{ height: 45, borderRadius: 2 }}
        >
          Generate URL
        </Button>
      </Box>

      {generatedUrl && (
        <Box mt={4}>
          <Typography variant="subtitle1">Generated URL:</Typography>
          <Typography
            variant="body1"
            sx={{
              wordBreak: "break-word",
              color: "primary.main",
              textDecoration: "underline",
              cursor: "pointer",
            }}
          >
            {generatedUrl}
          </Typography>
          <Tooltip
            title={copied ? "URL copied" : ""}
            open={copied}
            disableInteractive
            arrow
          >
            <Button
              variant="outlined"
              color="primary"
              size="small"
              onClick={handleCopyUrl}
              sx={{ ml: 2, mt: 2 }}
            >
              Copy URL
            </Button>
          </Tooltip>
          <Button
            variant="outlined"
            color="primary"
            size="small"
            onClick={handleSendText}
            sx={{ ml: 2, mt: 2 }}
          >
            Send Text
          </Button>
        </Box>
      )}
      <Box mt={3} sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          onClick={() => handleGetInventory(phoneNumber)}
          variant="contained"
          color="primary"
          sx={{ height: 45, borderRadius: 2 }}
        >
          Get Inventory
        </Button>
      </Box>

      {inventoryOptions.length > 0 && (
        <Box mt={3} display="flex" flexDirection="column" alignItems="center">
          <Typography variant="h6" sx={{ mb: 2, fontWeight: "bold" }}>
            Select Inventory Record:
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1.5,
              width: "100%",
              maxWidth: "400px",
            }}
          >
            {inventoryOptions.map((option, index) => (
              <Button
                key={index}
                variant="contained"
                sx={{
                  textAlign: "center",
                  padding: "10px 15px",
                  borderRadius: "8px",
                  fontSize: "14px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  backgroundColor:
                    "backgroundColor: theme.palette.primary.main",
                  "&:hover": {
                    backgroundColor: "#1259a6",
                  },
                }}
                onClick={() => handleSelectInventory(option)}
              >
                <Typography
                  variant="body1"
                  sx={{ fontWeight: "bold", color: "#fff" }}
                >
                  Created: {new Date(option.created_at).toLocaleString()}
                </Typography>
                <Typography variant="body2" sx={{ color: "#ddd" }}>
                  Updated: {new Date(option.updated_at).toLocaleString()}
                </Typography>
              </Button>
            ))}
          </Box>
        </Box>
      )}
      <Box mt={5}>
        <Typography variant="h5" gutterBottom sx={{ fontWeight: "bold" }}>
          Inventory Data
        </Typography>
        <TableContainer
          component={Paper}
          elevation={3}
          key={JSON.stringify(selectedInventory)}
        >
          <Table>
            <TableHead>
              <TableRow sx={{ backgroundColor: theme.palette.primary.main }}>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    color: "#fff",
                    textAlign: "center",
                  }}
                >
                  Room Name
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    color: "#fff",
                    textAlign: "center",
                  }}
                >
                  Image
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    color: "#fff",
                    textAlign: "center",
                  }}
                >
                  Object Name
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    color: "#fff",
                    textAlign: "center",
                  }}
                >
                  Count
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    color: "#fff",
                    textAlign: "center",
                  }}
                >
                  Bulky
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    color: "#fff",
                    textAlign: "center",
                  }}
                >
                  Cubic Feet
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    color: "#fff",
                    textAlign: "center",
                  }}
                >
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {selectedInventory && selectedInventory.length > 0 ? (
                selectedInventory.map((room: any, roomIndex: any) =>
                  room.images.map((image: any, imageIndex: any) =>
                    image.items.map((item: any, itemIndex: any) => (
                      <TableRow
                        key={`selected-${roomIndex}-${imageIndex}-${itemIndex}`}
                      >
                        {imageIndex === 0 && itemIndex === 0 && (
                          <TableCell
                            rowSpan={room.images.reduce(
                              (acc: any, img: any) => acc + img.items.length,
                              0
                            )}
                          >
                            <Typography
                              variant="h6"
                              sx={{ textAlign: "center" }}
                            >
                              {room.roomName}
                            </Typography>
                          </TableCell>
                        )}
                        {itemIndex === 0 && (
                          <TableCell
                            rowSpan={image.items.length}
                            sx={{ textAlign: "center" }}
                          >
                            <img
                              src={image.imageUrl}
                              alt="Room Image"
                              style={{
                                width: 130,
                                height: 130,
                                objectFit: "cover",
                                borderRadius: "7px",
                              }}
                            />
                            <Typography variant="h6">
                              Image: {imageIndex + 1}
                            </Typography>
                          </TableCell>
                        )}
                        <TableCell sx={{ textAlign: "center" }}>
                          {item.object_name}
                        </TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                          {item.count}
                        </TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                          {item.bulky ? "Yes" : "No"}
                        </TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                          {item.predicted_dimensions
                            ? Math.ceil(
                                item.predicted_dimensions.reduce(
                                  (acc: number, val: number) => acc * val,
                                  1
                                )
                              )
                            : "N/A"}
                        </TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                          <IconButton
                            color="error"
                            sx={{
                              backgroundColor: "white",
                              borderRadius: "50%",
                              "&:hover": { backgroundColor: "#ffebee" },
                            }}
                            onClick={() =>
                              handleDeleteItem(
                                room.roomName,
                                image.imageUrl,
                                item
                              )
                            }
                          >
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))
                  )
                )
              ) : inventoryData.length > 0 ? (
                inventoryData.map((inventoryItem, inventoryIndex) =>
                  inventoryItem.inventory.map((room: any, roomIndex: any) =>
                    room.images.map((image: any, imageIndex: any) =>
                      image.items.map((item: any, itemIndex: any) => (
                        <TableRow
                          key={`${inventoryIndex}-${roomIndex}-${imageIndex}-${itemIndex}`}
                        >
                          {imageIndex === 0 && itemIndex === 0 && (
                            <TableCell
                              rowSpan={room.images.reduce(
                                (acc: any, img: any) => acc + img.items.length,
                                0
                              )}
                            >
                              <Typography
                                variant="h6"
                                sx={{ textAlign: "center" }}
                              >
                                {room.roomName}
                              </Typography>
                            </TableCell>
                          )}
                          {itemIndex === 0 && (
                            <TableCell
                              rowSpan={image.items.length}
                              sx={{ textAlign: "center" }}
                            >
                              <img
                                src={image.imageUrl}
                                alt="Room Image"
                                style={{
                                  width: 130,
                                  height: 130,
                                  objectFit: "cover",
                                  borderRadius: "7px",
                                }}
                              />
                              <Typography
                                variant="h6"
                                sx={{ textAlign: "center" }}
                              >
                                Image: {imageIndex + 1}
                              </Typography>
                            </TableCell>
                          )}
                          <TableCell sx={{ textAlign: "center" }}>
                            {item.object_name}
                          </TableCell>
                          <TableCell sx={{ textAlign: "center" }}>
                            {item.count}
                          </TableCell>
                          <TableCell sx={{ textAlign: "center" }}>
                            {item.bulky ? "Yes" : "No"}
                          </TableCell>
                          <TableCell sx={{ textAlign: "center" }}>
                            {item.predicted_dimensions
                              ? Math.ceil(
                                  item.predicted_dimensions.reduce(
                                    (acc: number, val: number) => acc * val,
                                    1
                                  )
                                )
                              : "N/A"}{" "}
                          </TableCell>
                          <TableCell sx={{ textAlign: "center" }}>
                            <IconButton
                              color="error"
                              sx={{
                                backgroundColor: "white",
                                borderRadius: "50%",
                                "&:hover": { backgroundColor: "#ffebee" },
                              }}
                              onClick={() =>
                                handleDeleteItem(
                                  room.roomName,
                                  image.imageUrl,
                                  item
                                )
                              }
                            >
                              <DeleteIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))
                    )
                  )
                )
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={7}
                    align="center"
                    sx={{ textAlign: "center" }}
                  >
                    No inventory available yet
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Box>
          {showSaveButton && (
            <Box mt={3} sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSaveInventory}
                sx={{
                  backgroundColor: theme.palette.primary.main,
                  height: 45,
                  borderRadius: 2,
                }}
              >
                Save Inventory
              </Button>
            </Box>
          )}
        </Box>
        <Box mt={3}>
          <Typography variant="h6" gutterBottom sx={{ fontWeight: "bold" }}>
            Total Inventory Data
          </Typography>
          <TableContainer component={Paper} elevation={3}>
            <Table>
              <TableHead>
                <TableRow sx={{ backgroundColor: theme.palette.primary.main }}>
                  <TableCell
                    sx={{
                      fontWeight: "bold",
                      color: "#fff",
                      textAlign: "center",
                    }}
                  >
                    Total Volume (Cubic Feet)
                  </TableCell>
                  <TableCell
                    sx={{
                      fontWeight: "bold",
                      color: "#fff",
                      textAlign: "center",
                    }}
                  >
                    Total Weight (Pounds)
                  </TableCell>
                  <TableCell
                    sx={{
                      fontWeight: "bold",
                      color: "#fff",
                      textAlign: "center",
                    }}
                  >
                    Total Weight (Kilograms)
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell sx={{ textAlign: "center" }}>
                    {Math.ceil(Number(totalVolume.toFixed(3)))}
                  </TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    {Math.ceil(Number(totalWeightPounds.toFixed(3)))}
                  </TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    {Math.ceil(Number(totalWeightKg.toFixed(3)))}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>

      <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "auto",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            textAlign: "center",
            borderRadius: 2,
          }}
        >
          <Stack
            spacing={2}
            direction={"row"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Typography fontSize={16} gutterBottom color="error">
              {error}
            </Typography>
            {successMessage && (
              <Typography fontSize={16} gutterBottom color="green">
                {successMessage}
              </Typography>
            )}
            <Button
              onClick={() => setModalOpen(false)}
              variant="contained"
              color="primary"
            >
              close
            </Button>
          </Stack>
        </Box>
      </Modal>

      <Box mt={4}>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleDownloadPDF}
          sx={{
            backgroundColor: theme.palette.primary.main,
            mb: 3,
            display: "block",
            marginLeft: "auto",
          }}
        >
          Download PDF Report
        </Button>
      </Box>
    </Container>
  );
};

export default InventoryPopulate;
