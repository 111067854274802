import {
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Grid,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { consumerLogin, verifyConsumerOtp } from "../../redux/actions/consumer";
import { unwrapResult } from "@reduxjs/toolkit";
import { verifyDeliveryCarrierOtp } from "../../redux/actions/delivery-carrier";
import { verifyPickupCarrierOtp } from "../../redux/actions/pickup-carrier";
import WeMoveHeader from "../../components/header/weMoveHeader";
import { verifyLocalCarrierOtp } from "../../redux/actions/local-carrier";

const numberOfDigits = 6;

const OtpVerification = () => {
  const theme = useTheme();
  // navigate(`/packages/${moveRequestId}`);

  const currentMoveRequest = localStorage.getItem("current-move-request");
  const moveRequest = currentMoveRequest ? JSON.parse(currentMoveRequest) : null;
  const moveRequestId = moveRequest?.canonical_id;
  

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { user, role } = location.state || {};

  const [otp, setOtp] = useState(new Array(numberOfDigits).fill(""));
  const [otpError, setOtpError] = useState("");
  const [isPageLoading, setIsPageLoading] = useState(false);
  const otpBoxReference = useRef<any[]>([]);

  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [showOtpSuccess, setShowOtpSuccess] = useState(false);

  const [timer, setTimer] = useState(30);

  function handleChange(value: any, index: any) {
    let newArr = [...otp];
    newArr[index] = value;
    setOtp(newArr);

    if (value && index < numberOfDigits - 1) {
      otpBoxReference.current[index + 1].focus();
    }
  }

  function handleBackspaceAndEnter(e: any, index: any) {
    if (e.key === "Backspace" && !e.target.value && index > 0) {
      otpBoxReference.current[index - 1].focus();
    }
    if (e.key === "Enter" && e.target.value && index < numberOfDigits - 1) {
      otpBoxReference.current[index + 1].focus();
    }
  }

  const handleResendCode = () => {
    const body = {
      phone_number: user.phone_number,
    };

    dispatch<any>(consumerLogin(body))
      .then(unwrapResult)
      .then((res: any) => {
        setShowOtpSuccess(true);
        setTimeout(() => {
          setShowOtpSuccess(false);
        }, 1500);
      })
      .catch((err: any) => {
        console.log(err);
      });

    setIsButtonDisabled(true);
    setTimer(30);
  };

  const varifyOTP = () => {
    setIsPageLoading(true);

    const otpString = otp.join("");

    const body = {
      phone_number:
        role === "consumer" ? user.phone_number : user.owner_phone_number,
      otp: otpString,
    };

    if (role === "consumer") {
      dispatch<any>(verifyConsumerOtp(body))
        .then(unwrapResult)
        .then((res: any) => {
          if (res) {
            const token = localStorage.getItem("jwtToken");
            const userObject = JSON.stringify(user);
            localStorage.setItem("user-object", userObject);
            localStorage.setItem("role", role);

            const userOrigin = localStorage.getItem("user-origin")
            setTimeout(() => {
              setIsPageLoading(false);
              if(userOrigin === "user-verification"){
                navigate(`/move-summary/${moveRequestId}`);
              } else if (userOrigin === "login"){
                navigate(`/ConsumerDashboard/${user.canonical_id}`);
              }
            }, 1300);
          }
        })
        .catch((err: any) => {
          setOtpError(err);
          setTimeout(() => {
            setIsPageLoading(false);
          }, 1300);
        });
    } else if (role === "delivery_carrier") {
      dispatch<any>(verifyDeliveryCarrierOtp(body))
        .then(unwrapResult)
        .then(async (res: any) => {
          if (res) {
            const token = localStorage.getItem("jwtToken");
            setTimeout(() => {
              setIsPageLoading(false);
              navigate(`/deliveryCarrierDashboard/${user.canonical_id}`);
            }, 1300);
          }
        })
        .catch((err: any) => {
          setOtpError(err);
          setTimeout(() => {
            setIsPageLoading(false);
          }, 1300);
        });
    } else if (role === "pickup_carrier") {
      dispatch<any>(verifyPickupCarrierOtp(body))
        .then(unwrapResult)
        .then((res: any) => {
          if (res) {
            const token = localStorage.getItem("jwtToken");
            setTimeout(() => {
              setIsPageLoading(false);
              navigate(`/pickupCarrierDashboard/${user.canonical_id}`);
            }, 1300);
          }
        })
        .catch((err: any) => {
          setOtpError(err);
          setTimeout(() => {
            setIsPageLoading(false);
          }, 1300);
        });
    } else if (role === "local_carrier") {
      dispatch<any>(verifyLocalCarrierOtp(body))
        .then(unwrapResult)
        .then((res: any) => {
          if (res) {
            const token = localStorage.getItem("jwtToken");
            setTimeout(() => {
              setIsPageLoading(false);
            }, 1300);
          }
        })
        .catch((err: any) => {
          setOtpError(err);
          setTimeout(() => {
            setIsPageLoading(false);
          }, 1300);
        });
    }
  };

  useEffect(() => {
    let countdown: any;

    const startTimer = () => {
      countdown = setInterval(() => {
        setTimer((prevTimer) => {
          if (prevTimer === 1) {
            clearInterval(countdown);
            setIsButtonDisabled(false);
          }
          return prevTimer - 1;
        });
      }, 1000);
    };

    if (isButtonDisabled) {
      startTimer();
    }

    return () => {
      clearInterval(countdown);
    };
  }, [isButtonDisabled]);

  return (
    <>
      <WeMoveHeader />
      <Grid container>
        <Grid item xs={12} sm={8} md={5} mt={2} p={2} marginX={"auto"}>
          <Stack alignItems={"center"}>
            <Typography>OTP Verification</Typography>

            <Typography fontSize={12} mt={2} textAlign="start">
              {role === "consumer" && !user.Email_verified ? (
                <>an OTP has been sent to your Email</>
              ) : (
                <>an OTP has been sent to your phone</>
              )}
            </Typography>
            <Box display="flex" justifyContent="center">
              {otp.map((digit, index) => (
                <TextField
                  key={index}
                  value={digit}
                  type="text"
                  required
                  error={Boolean(otpError)}
                  onChange={(e) => handleChange(e.target.value, index)}
                  onKeyUp={(e) => handleBackspaceAndEnter(e, index)}
                  inputRef={(ref) => (otpBoxReference.current[index] = ref)}
                  inputProps={{
                    min: 0,
                    maxLength: 1,
                    pattern: "[0-9]*",
                    inputMode: "numeric",
                    style: {
                      background: "#5A7BFC14",
                      borderRadius: "8px",
                    },
                  }}
                  sx={{
                    width: 42,
                    marginLeft: 1,
                    marginTop: 1,
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "#5A7BFC59",
                        borderWidth: "1.43",
                        borderRadius: "8px",
                      },
                    },
                  }}
                />
              ))}
            </Box>

            <Typography
              mt={2}
              textAlign={"center"}
              fontSize={14}
              fontWeight={550}
              color={"#FF0000"}
              sx={{ minHeight: 20, textTransform: 'uppercase'  }}
            >
              {otpError !== "" ? otpError : " "}
            </Typography>

            <Typography fontWeight={600} mt={1} fontSize={12}>
              Did not receive the code? {isButtonDisabled ? "00:" + timer : ""}
            </Typography>

            <Button onClick={handleResendCode} disabled={isButtonDisabled}>
              <Typography fontWeight={600} fontSize={12}>
                Resend code
              </Typography>
            </Button>

            <Button
              variant="contained"
              type="submit"
              onClick={varifyOTP}
              sx={{
                marginTop: 3,
                height: 50,
                width: "50%",
                background: theme.palette.primary.main,
                fontSize: 12,
                fontWeight: 550,
              }}
            >
              Verify
            </Button>
          </Stack>

          {showOtpSuccess && (
            <Alert severity="success">
              verification code sent successfully!
            </Alert>
          )}

          {isPageLoading && (
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={isPageLoading}
              onClick={() => {
                setIsPageLoading(true);
              }}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          )}
        </Grid>
      </Grid>
    </>
  );
};
export default OtpVerification;
