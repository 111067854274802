export const getCarrierEmail = () => {
  if (
    process.env.REACT_APP_TENANT === "wemove" ||
    process.env.REACT_APP_TENANT === "turbotenant" ||
    process.env.REACT_APP_TENANT === "sold"
  ) {
    return "travis@wemove.ai";
  } else if (process.env.REACT_APP_TENANT === "rockstarspromoverscomplete") {
    return "lead1@rockstarpromovers.com";
  } else if (process.env.REACT_APP_TENANT === "elevatedliving") {
    return "jacob@wemove.ai";
  } else if (process.env.REACT_APP_TENANT === "harrington-movers") {
    return "jeff@harringtonmovers.com";
  } else if (process.env.REACT_APP_TENANT === "dependable-movers") {
    return "gulia@dependablemoverssf.com";
  } else if (process.env.REACT_APP_TENANT === "justintimemoving") {
    return "justin@jitmoving.com";
  } else if (
    process.env.REACT_APP_TENANT === "quicknickmoving" ||
    process.env.REACT_APP_TENANT === "quicknick-moving"
  ) {
    return "noah@quicknickdelivery.com";
  } else if (process.env.REACT_APP_TENANT === "conroymoving") {
    return "Luann@majesticmountainmovers.com";
  } else if (
    process.env.REACT_APP_TENANT === "miraclemoversutah" ||
    process.env.REACT_APP_TENANT === "miraclemovers-utah"
  ) {
    return "contact@miraclemoversutah.com";
  } else if (process.env.REACT_APP_TENANT === "majesticmountainmovers") {
    return "Luann@majesticmountainmovers.com";
  } else if (process.env.REACT_APP_TENANT === "skitownmoving") {
    return "info@skitownmoving.com";
  } else if (process.env.REACT_APP_TENANT === "centralcoastmoving") {
    return "Info@centralcoastmoving.com";
  } else return "travis@wemove.ai";
};
