export const getCarrierTitle = () => {
  if (
    process.env.REACT_APP_TENANT === "wemove" ||
    process.env.REACT_APP_TENANT === "turbotenant" ||
    process.env.REACT_APP_TENANT === "sold"
  ) {
    return "WeMove";
  } else if (process.env.REACT_APP_TENANT === "rockstarspromoverscomplete") {
    return "Rockstar Pro Movers";
  } else if (process.env.REACT_APP_TENANT === "elevatedliving") {
    return "Elevated Living";
  } else if (process.env.REACT_APP_TENANT === "harrington-movers") {
    return "Harrington Movers";
  } else if (process.env.REACT_APP_TENANT === "dependable-movers") {
    return "Dependable Movers";
  } else if (process.env.REACT_APP_TENANT === "justintimemoving") {
    return "JIT Moving";
  } else if (
    process.env.REACT_APP_TENANT === "quicknickmoving" ||
    process.env.REACT_APP_TENANT === "quicknick-moving"
  ) {
    return "Quick Nick Moving";
  } else if (process.env.REACT_APP_TENANT === "conroymoving") {
    return "Conroy Moving";
  } else if (
    process.env.REACT_APP_TENANT === "miraclemoversutah" ||
    process.env.REACT_APP_TENANT === "miraclemovers-utah"
  ) {
    return "Miracle Movers Utah";
  } else if (process.env.REACT_APP_TENANT === "majesticmountainmovers") {
    return "Majestic Mountain Movers";
  } else if (process.env.REACT_APP_TENANT === "skitownmoving") {
    return "Skitown Moving";
  } else if (process.env.REACT_APP_TENANT === "centralcoastmoving") {
    return "Central Coast Moving";
  } else return "WeMove";
};
