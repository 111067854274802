import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Stack, IconButton, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import logoCube from "../../assets/logo/Group 15.png";
import logoWeMove from "../../assets/logo/WEMOVE.png";
import QRCode from "react-qr-code";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getMoveRequestById } from "../../redux/actions/move-request";
import { unwrapResult } from "@reduxjs/toolkit";
import MoveBeesLogo from "../../assets/logo/MoveBees-Logo-VR-Yellow.jpg";
import KruppLogo from "../../assets/logo/Krupp.png";
import SummitMovingLogo from "../../assets/logo/SummitMoving.png";
import TurboTenantLogo from "../../assets/logo/turbotenant_logo.png";
import theme from "../../theme/theme";

const UploadMoreImagesModal = ({
  uploadMoreImagesModalVisile,
  setUploadMoreImagesModalVisile,
  actionType,
  moveRequest,
}: any) => {
  const currentTenant = process.env.REACT_APP_TENANT;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { moveRequestId } = useParams();
  const sessionId = moveRequestId;
  const url = process.env.REACT_APP_URL;

  const [jwtToken, setJwtToken] = useState("");
  const [thisMoveRequestId, setThisMoveRequestId] = useState(null);
  const replaceUrl = (url: string) => {
    if (process.env.REACT_APP_TENANT !== "turbotenant") {
      const updatedUrl = url?.replace("wemoveai", "driftedai");
      return updatedUrl || "";
    } else {
      return url;
    }
  };
  // const urlForScanning = `${url}/upload-inventory/${thisMoveRequestId}?tokenvalue=${jwtToken}&sessionId=${sessionId}`;
  // const localURL = `http://localhost:3000/upload-inventory/${thisMoveRequestId}?tokenvalue=${jwtToken}&sessionId=${sessionId}`;
  const urlForScanning = `${replaceUrl(url || "")}/upload-inventory/${moveRequestId}?&sessionId=${sessionId}`;
  const localURL = `http://localhost:3000/upload-inventory/${moveRequestId}?&sessionId=${sessionId}`;
  const handleClose = () => {
    setUploadMoreImagesModalVisile(false);
    localStorage.removeItem("action-type");
  };

  const fetchMoveRequestById = (moveRequestId: any) => {
    dispatch<any>(getMoveRequestById(moveRequestId))
      .then(unwrapResult)
      .then((thisMoveRequest: any) => {
        setThisMoveRequestId(thisMoveRequest.canonical_id);
        const token = localStorage.getItem("jwtToken");
        setJwtToken(token || "");
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  const handleNavigation = () => {
    navigate(`/upload-inventory/${moveRequest.canonical_id}`);
  };

  useEffect(() => {
    if (actionType === "add-item") {
      localStorage.setItem("action-type", "add-item");
    } else if (actionType === "edit") {
      localStorage.setItem("action-type", "edit");
    }
  }, []);

  useEffect(() => {
    if (moveRequestId) {
      fetchMoveRequestById(moveRequestId);
    }
  }, [moveRequestId]);

  return (
    <Modal
      open={uploadMoreImagesModalVisile}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: { xs: "90vw", sm: "80vw", md: "50vw", lg: "40vw" },
          height: { xs: "80vh", md: "auto" },
          overflow: "auto",
          bgcolor: "background.paper",
          borderRadius: "8px",
          boxShadow: 24,
          padding: 4,
          border: "2px solid black",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <IconButton
          sx={{ position: "absolute", top: 8, right: 8 }}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>

        <Stack
          alignItems={"center"}
          justifyContent={"center"}
          direction={"row"}
          mt={2}
        >
          {currentTenant === "wemove" || currentTenant === "sold" ? (
            <>
              <img src={logoCube} width={30} height={30} alt="" />
              <img src={logoWeMove} width={110} height={18} alt="" />
            </>
          ) : currentTenant === "turbotenant" ? (
            <img src={TurboTenantLogo} width={150} height={44} alt="" />
          ) : currentTenant === "movebees" ? (
            <img
              src={MoveBeesLogo}
              style={{ borderRadius: 6 }}
              width={240}
              height={66}
              alt=""
            />
          ) : currentTenant === "summitmoving" ? (
            <img src={SummitMovingLogo} width={150} height={44} alt="" />
          ) : currentTenant === "krupp" ? (
            <img src={KruppLogo} width={150} height={44} alt="" />
          ) : (
            <></>
          )}
        </Stack>
        <Stack p={2} spacing={2} textAlign="center">
          <Typography
            color={theme.palette.primary.main}
            fontSize={18}
            fontWeight={600}
          >
            Scan This QR Code on Phone!
          </Typography>

          <Box marginTop={2}>
            <QRCode size={120} value={urlForScanning} viewBox={`0 0 256 256`} />
          </Box>

          <Typography
            color={theme.palette.primary.main}
            fontSize={18}
            fontWeight={600}
          >
            OR! Navigate from here.
          </Typography>

          <Button
            sx={{
              "&:hover": {
                backgroundColor: "#000000", // Hover background color
                color: "#FDFCFD", // Hover text color
              },
            }}
            onClick={() => {
              handleNavigation();
            }}
            variant="contained"
          >
            Navigate
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};

export default UploadMoreImagesModal;
