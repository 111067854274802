import { createTheme } from "@mui/material";

const currentTenant = process.env.REACT_APP_TENANT || "";

let primaryColor = "#5858E0";
let secondaryColor = "#19857b"; // Default secondary color

if (currentTenant === "wemove" || currentTenant === "sold") {
  primaryColor = "#0257CF";
} else if (currentTenant === "turbotenant") {
  primaryColor = "#033a6d";
} else if (currentTenant === "movebees") {
  primaryColor = "#dbb20d";
} else if (currentTenant === "krupp") {
  primaryColor = "#01565E";
} else if (currentTenant === "summitmoving") {
  primaryColor = "#C4161C";
} else if (currentTenant === "rockstarspromoverspartial") {
  primaryColor = "#ff0000";
} else if (currentTenant === "rockstarspromoverscomplete") {
  primaryColor = "#ff0000";
} else if (currentTenant === "dearmanmoving") {
  primaryColor = "#3854A4";
} else if (currentTenant === "safewaymoving") {
  primaryColor = "#ff5c39";
} else if (currentTenant === "royalmovingco") {
  primaryColor = "#d9ae3d";
} else if (currentTenant === "harringtonmovers") {
  primaryColor = "#67a671";
} else if (currentTenant === "elevatedliving") {
  primaryColor = "#c2996c";
} else if (currentTenant === "harrington-movers") {
  primaryColor = "#67a671";
} else if (currentTenant === "justintimemoving") {
  primaryColor = "#1a396f";
} else if (
  currentTenant === "dependable-movers" ||
  currentTenant === "dependablemovers"
) {
  primaryColor = "#005949";
} else if (
  currentTenant === "quicknick-moving" ||
  currentTenant === "quicknickmoving"
) {
  primaryColor = "#14487b";
  secondaryColor = "#4cb65e";
} else if (
  currentTenant === "miraclemoversutah" ||
  currentTenant === "miraclemovers-utah"
) {
  primaryColor = "#02389e";
} else if (currentTenant === "conroymoving") {
  primaryColor = "#982928";
} else if (currentTenant === "majesticmountainmovers") {
  primaryColor = "#8052a3";
} else if (currentTenant === "skitownmoving") {
  primaryColor = "#a26028";
} else if (currentTenant === "centralcoastmoving") {
  primaryColor = "#0088ff";
}

const theme = createTheme({
  palette: {
    primary: {
      main: primaryColor,
      light: "#F2F4FF",
      dark: "#6552FF",
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: secondaryColor,
    },
  },
});

export default theme;
