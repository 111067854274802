import {
  Box,
  Button,
  Grid,
  IconButton,
  InputLabel,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { useEffect, useState } from "react";
import ModifyPackagingModal from "../modals/modifyPackagingModal";
import { useNavigate } from "react-router-dom";

const priceInfoBox = {
  p: 1.3,
  width: 240,
  borderRadius: 2,
};

const MoveRequestDetail = ({
  moveRequest,
  setIsMoveRequestDetail,
  setIsShowAllMoveRequests,
  setMoveRequest,
  thisMoveRequest,
  navigateToLastLeft,
  executeLoader,
  isLoading,
  checkTenant,
  url,
}: any) => {
  const theme = useTheme();
  const isMobileOrTab = useMediaQuery(theme.breakpoints.down("md"));
  const notSeelectedButtonStyle = {
    color: `${theme.palette.primary.main}`,
    fontSize: 12,
    fontWeight: 550,
    border: `1px solid ${theme.palette.primary.main}`,
  };

  const selectedButtonStyle = {
    backgroundColor: `${theme.palette.primary.main} !important`,
    color: "#FDFCFD",
    fontSize: 12,
    fontWeight: 550,
  };
  const formatDate = (dateString: any) => {
    if (!dateString) return null;
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    return `${day}-${month}-${year}`;
  };

  const checkFromAddress = (moveReq: any) => {
    if (moveReq.home_address !== null) {
      return moveReq.home_address;
    }
    if (moveReq.apartment !== null) {
      return moveReq.apartment?.apt_address;
    }
    if (moveReq.storage !== null) {
      return moveReq.storage?.address;
    }
    if (moveReq.combo_home_storage !== null) {
      return (
        moveReq.combo_home_storage?.home_address.toString() +
        "/" +
        moveReq.combo_home_storage.storage?.address.toString()
      );
    }
    if (moveReq.combo_apartment_storage !== null) {
      return (
        moveReq.combo_apartment_storage?.apartment?.apt_address.toString() +
        "/" +
        moveReq.combo_apartment_storage?.storage?.address.toString()
      );
    }
  };

  const handleBack = () => {
    setIsMoveRequestDetail(null);
    setIsShowAllMoveRequests(true);
  };
  const navigate = useNavigate();
  const [modifyPackagingModalVisible, setModifyPackagingModalVisible] =
    useState(false);

  return (
    <>
      <Grid container justifyContent={"space-between"}>
        {modifyPackagingModalVisible && (
          <ModifyPackagingModal
            modifyPackagingModalVisible={modifyPackagingModalVisible}
            setModifyPackagingModalVisible={setModifyPackagingModalVisible}
            moveRequest={moveRequest}
            setMoveRequest={setMoveRequest}
          />
        )}
        {isLoading && executeLoader()}
        <Stack direction={"row"} alignItems={"center"}>
          {url === "https://staging.wemoveai.com" && (
            <IconButton
              onClick={() => {
                handleBack();
              }}
              sx={{
                backgroundColor: `${theme.palette.primary.main} !important`,
                color: "#FDFCFD",
              }}
            >
              <ArrowBackIcon />
            </IconButton>
          )}
          <Typography ml={2} fontWeight={700} fontSize={14} color={"#374145"}>
            Hello _
          </Typography>
          <Typography fontWeight={700} fontSize={14} ml={1} color={"#374145"}>
            {moveRequest?.user?.first_name ? moveRequest?.user?.first_name : ""}{" "}
            {moveRequest?.user?.last_name ? moveRequest?.user?.last_name : ""}
          </Typography>
          <Typography fontWeight={400} fontSize={12} ml={1} color={"#374145"}>
            here's what's happening
          </Typography>
        </Stack>

        <Grid
          item
          xs={12}
          mt={2}
          p={2}
          sx={{
            background: "#FFFFFF",
            borderTop: `1.5px solid ${theme.palette.primary.main}`,
            borderRadius: 2,
          }}
        >
          <Stack
            alignItems={"center"}
            justifyContent={"space-between"}
            direction={"row"}
          >
            <Stack
              justifyContent={"center"}
              alignItems={"center"}
              sx={{
                backgroundColor: theme.palette.primary.main,
                width: 148,
                borderRadius: 2,
              }}
            >
              <Typography
                fontWeight={550}
                fontSize={isMobileOrTab ? 14 : 20}
                color={"#FFFFFF"}
              >
                DASHBOARD
              </Typography>
            </Stack>
          </Stack>
          {url === "https://production.wemoveai.com" ||
          url === "https://turbotenant.wemoveai.com" ||
          url === "https://sold.wemoveai.com" ||
          url === "https://kruppmoving.wemoveai.com" ||
          url === "https://movebees.wemoveai.com" ||
          url === "https://summitmoving.wemoveai.com" ||
          url === "https://rockstarspropartial.wemoveai.com" ||
          url === "https://rockstarsprocomplete.wemoveai.com" ||
          url === "https://dearmanmoving.wemoveai.com" ||
          url === "https://safewaymoving.wemoveai.com" ||
          url === "https://harringtonmovers.wemoveai.com" ||
          url === "https://elevatedliving.wemoveai.com" ||
          url === "https://harrington-movers.wemoveai.com" ||
          url === "https://dependable-movers.wemoveai.com" ||
          url === "https://dependablemovers.wemoveai.com" ||
          url === "https://jitmoving.wemoveai.com" ||
          url === "https://quicknickmoving.wemoveai.com" ||
          url === "https://quicknick-moving.wemoveai.com" ||
          url === "https://miraclemoversutah.wemoveai.com" ||
          url === "https://miraclemovers-utah.wemoveai.com" || 
          url === "https://majesticmountainmovers.wemoveai.com"||
          url === "https://skitownmoving.wemoveai.com"||
          url === "https://centralcoastmoving.wemoveai.com"
           ? (
            <>
              {thisMoveRequest?.move_type === null ||
              thisMoveRequest?.location_type === null ||
              thisMoveRequest?.delivery_details === null ||
              thisMoveRequest?.delivery_details?.delivery_addresses?.length ===
                0 ||
              (thisMoveRequest?.delivery_details?.packagaing_required ===
                null &&
                checkTenant() === true) ||
              thisMoveRequest?.pickup_date_from === null ||
              thisMoveRequest?.pickup_date_to === null ||
              thisMoveRequest?.delivery_details?.open_location === null ||
              thisMoveRequest?.boxable_items_volume === null ||
              thisMoveRequest?.no_of_boxes_for_boxable_items === null ||
              ((thisMoveRequest?.initial_deposit === null ||
                thisMoveRequest?.contract_signed === null ||
                thisMoveRequest?.contract_signed === false) &&
                checkTenant() === true) ? (
                <Stack
                  alignSelf={"flex-end"}
                  spacing={2}
                  alignItems={"center"}
                  direction={"row"}
                >
                  <Typography
                    fontSize={isMobileOrTab ? 12 : 14}
                    fontWeight={600}
                    color={"#FF0000"}
                  >
                    Your move information is incomplete!
                  </Typography>
                  <Button
                    onClick={() => {
                      navigateToLastLeft(thisMoveRequest);
                    }}
                    sx={{
                      borderColor: theme.palette.primary.main,
                      bgcolor: theme.palette.primary.main,
                      color: "#FFFFFF",
                      fontSize: 10,
                      "&:hover": {
                        backgroundColor: "#000000",
                        color: "#FFFFFF",
                        fontSize: 10,
                      },
                    }}
                    variant="outlined"
                  >
                    Complete Move
                  </Button>
                </Stack>
              ) : (
                <Typography
                  fontSize={isMobileOrTab ? 12 : 14}
                  fontWeight={600}
                  color={"#008000"}
                >
                  All the required information is complete.
                </Typography>
              )}
            </>
          ) : null}
          <Typography
            mt={2}
            fontWeight={550}
            fontSize={16}
            color={theme.palette.primary.main}
          >
            Personal Information
          </Typography>

          <Box
            mt={2}
            display={isMobileOrTab ? "block" : "flex"}
            justifyContent={"space-between"}
          >
            <Stack>
              <InputLabel
                sx={{
                  fontSize: 14,
                }}
                htmlFor="name"
              >
                Name
              </InputLabel>

              <TextField
                id="name"
                variant="outlined"
                margin="dense"
                size="small"
                value={
                  moveRequest.user
                    ? moveRequest?.user?.first_name +
                      " " +
                      moveRequest?.user?.last_name
                    : "-"
                }
                sx={{
                  width: 300,
                }}
              />
            </Stack>

            <Stack>
              <InputLabel
                sx={{
                  fontSize: 14,
                }}
                htmlFor="phoneNo"
              >
                Phone No.
              </InputLabel>

              <TextField
                id="phoneNo"
                variant="outlined"
                margin="dense"
                size="small"
                value={moveRequest?.user?.phone_number || "-"}
                sx={{
                  width: 300,
                }}
              />
            </Stack>

            <Stack>
              <InputLabel
                sx={{
                  fontSize: 14,
                }}
                htmlFor="email"
              >
                Email ID
              </InputLabel>
              <TextField
                id="email"
                variant="outlined"
                margin="dense"
                size="small"
                value={moveRequest?.user?.email || "-"}
                sx={{
                  width: 300,
                }}
              />
            </Stack>
          </Box>
        </Grid>

        <Grid
          item
          xs={12}
          md={5.9}
          mt={2}
          p={2}
          sx={{
            background: "#FFFFFF",
            borderTop: `1.5px solid ${theme.palette.primary.main}`,
            borderRadius: 2,
          }}
        >
          <Typography
            fontWeight={550}
            fontSize={16}
            color={theme.palette.primary.main}
          >
            More Information
          </Typography>

          <InputLabel
            sx={{
              fontSize: 14,
            }}
            htmlFor="referenceNo"
          >
            Reference Number
          </InputLabel>

          <TextField
            id="referenceNo"
            variant="outlined"
            margin="dense"
            size="small"
            value={moveRequest?.move_order_number || "-"}
            sx={{
              width: 300,
            }}
          />

          <Typography
            fontWeight={400}
            fontSize={14}
            mt={2}
            color={"rgba(0, 0, 0, 0.6)"}
          >
            Move Type
          </Typography>
          <Stack direction="row" mt={1} spacing={2}>
            <Button
              size="large"
              sx={
                moveRequest?.move_distance && moveRequest?.move_distance <= 100
                  ? selectedButtonStyle
                  : notSeelectedButtonStyle
              }
            >
              Local Move
            </Button>
            <Button
              size="large"
              sx={
                moveRequest?.move_distance && moveRequest?.move_distance > 100
                  ? selectedButtonStyle
                  : notSeelectedButtonStyle
              }
            >
              Long Distance Move
            </Button>
          </Stack>

          <InputLabel
            sx={{
              fontSize: 14,
              mt: 2,
            }}
            htmlFor="pickupAddress"
          >
            Pickup Address
          </InputLabel>

          <TextField
            id="pickupAddress"
            variant="outlined"
            margin="dense"
            size="small"
            value={checkFromAddress(moveRequest) || "-"}
            sx={{
              width: 300,
            }}
          />

          <InputLabel
            sx={{
              fontSize: 14,
              mt: 2,
            }}
            htmlFor="deliveryAddress"
          >
            Delivery Address
          </InputLabel>

          <TextField
            id="deliveryAddress"
            defaultValue={"1234555DFC67"}
            variant="outlined"
            margin="dense"
            size="small"
            value={
              moveRequest.delivery_details?.delivery_addresses?.length
                ? moveRequest.delivery_details.delivery_addresses[0]
                    .complete_address
                : "-"
            }
            sx={{
              width: 300,
            }}
          />

          <InputLabel
            sx={{
              fontSize: 14,
              mt: 2,
            }}
            htmlFor="totalDistance"
          >
            Total Distance
          </InputLabel>

          <TextField
            id="totalDistance"
            defaultValue={"1234"}
            variant="outlined"
            margin="dense"
            size="small"
            value={
              moveRequest?.move_distance
                ? `${Math.round(moveRequest.move_distance)} miles`
                : "-"
            }
            sx={{
              width: 300,
            }}
          />

          <Typography
            fontWeight={400}
            fontSize={14}
            mt={2}
            color={"rgba(0, 0, 0, 0.6)"}
          >
            Shuttle Required
          </Typography>
          {moveRequest?.delivery_details !== null &&
          moveRequest?.delivery_details?.shuttle_required !== null ? (
            <Stack direction="row" mt={1} spacing={2}>
              <Button
                size="large"
                sx={
                  moveRequest?.delivery_details.shuttle_required === "YES"
                    ? selectedButtonStyle
                    : notSeelectedButtonStyle
                }
              >
                Yes
              </Button>
              <Button
                size="large"
                sx={
                  moveRequest?.delivery_details.shuttle_required === "NO"
                    ? selectedButtonStyle
                    : notSeelectedButtonStyle
                }
              >
                No
              </Button>
              <Button
                size="large"
                sx={
                  moveRequest?.delivery_details.shuttle_required === "UNSURE"
                    ? selectedButtonStyle
                    : notSeelectedButtonStyle
                }
              >
                UNSURE
              </Button>
            </Stack>
          ) : (
            <Typography
              fontWeight={400}
              fontSize={12}
              mt={1}
              color={"rgba(0, 0, 0, 0.6)"}
            >
              Not yet selected
            </Typography>
          )}

          <InputLabel
            sx={{
              fontSize: 14,
              mt: 2,
            }}
            htmlFor="fdd"
          >
            Move Date
          </InputLabel>

          <TextField
            id="fdd"
            variant="outlined"
            margin="dense"
            size="small"
            value={formatDate(moveRequest?.pickup_date_from) || "-"}
            sx={{
              width: 300,
            }}
          />
        </Grid>

        {process.env.REACT_APP_TENANT === "wemove" ||
        process.env.REACT_APP_TENANT === "turbotenant" ||
        process.env.REACT_APP_TENANT === "rockstarspromoverscomplete" ||
        process.env.REACT_APP_TENANT === "sold" ||
        process.env.REACT_APP_TENANT === "harringtonmovers" ||
        process.env.REACT_APP_TENANT === "quicknick-moving" ||
        process.env.REACT_APP_TENANT === "dependable-movers" ||
        process.env.REACT_APP_TENANT === "mircalemovers-utah" ? (
          <Grid
            item
            xs={12}
            md={5.9}
            mt={2}
            p={2}
            sx={{
              background: "#FFFFFF",
              borderTop: `1.5px solid ${theme.palette.primary.main}`,
              borderRadius: 2,
            }}
          >
            <Box>
              <Typography
                fontWeight={550}
                fontSize={16}
                color={theme.palette.primary.main}
              >
                Packaging Information
              </Typography>

              <Stack
                alignItems={"center"}
                alignContent={"center"}
                direction={"row"}
              >
                <Typography
                  fontWeight={400}
                  fontSize={14}
                  mt={2}
                  color={"rgba(0, 0, 0, 0.6)"}
                >
                  Packaging Service
                </Typography>
                <Button
                  onClick={() => {
                    setModifyPackagingModalVisible(true);
                  }}
                  variant="text"
                  sx={{ alignSelf: "center", marginTop: 2 }}
                >
                  <Stack spacing={1} alignItems={"center"} direction={"row"}>
                    <Typography fontSize={14}>Edit</Typography>
                    <EditOutlinedIcon fontSize="small" />
                  </Stack>
                </Button>
              </Stack>
              {moveRequest?.delivery_details !== null &&
              moveRequest?.delivery_details?.packagaing_required !== null ? (
                <Stack direction="row" mt={1} spacing={2}>
                  <Button
                    size="large"
                    sx={
                      moveRequest?.delivery_details?.packagaing_required
                        ? selectedButtonStyle
                        : notSeelectedButtonStyle
                    }
                  >
                    Yes
                  </Button>
                  <Button
                    size="large"
                    sx={
                      !moveRequest?.delivery_details?.packagaing_required
                        ? selectedButtonStyle
                        : notSeelectedButtonStyle
                    }
                  >
                    No
                  </Button>
                </Stack>
              ) : (
                <Typography
                  fontWeight={400}
                  fontSize={12}
                  mt={1}
                  color={"rgba(0, 0, 0, 0.6)"}
                >
                  Not yet selected
                </Typography>
              )}

              <Typography
                fontWeight={400}
                fontSize={14}
                mt={2}
                color={"rgba(0, 0, 0, 0.6)"}
              >
                Type of Packing Service
              </Typography>
              {moveRequest?.delivery_details !== null &&
              moveRequest?.delivery_details?.packaging !== null ? (
                <Stack direction="row" mt={1} spacing={2}>
                  <Button
                    size="large"
                    sx={
                      moveRequest?.delivery_details?.packaging
                        ?.packaging_type === "Full Package"
                        ? selectedButtonStyle
                        : notSeelectedButtonStyle
                    }
                  >
                    Full
                  </Button>
                  {/* <Button
                  size="large"
                  sx={
                    moveRequest?.delivery_details?.packaging?.packaging_type !==
                    "Full Package"
                      ? selectedButtonStyle
                      : notSeelectedButtonStyle
                  }
                >
                  Partial
                </Button> */}
                </Stack>
              ) : (
                <Typography
                  fontWeight={400}
                  fontSize={12}
                  mt={1}
                  color={"rgba(0, 0, 0, 0.6)"}
                >
                  Not yet selected.
                </Typography>
              )}
            </Box>
            <Box mt={2}>
              <Typography
                fontWeight={550}
                fontSize={16}
                color={theme.palette.primary.main}
              >
                Pricing Information
              </Typography>

              <Box mt={2} display={isMobileOrTab ? "block" : "flex"}>
                <Stack gap={1} p={1}>
                  <Box sx={{ ...priceInfoBox, backgroundColor: "#F0F0FC" }}>
                    <Typography
                      fontWeight={550}
                      fontSize={14}
                      color={"#374145"}
                    >
                      Packing Service Cost
                    </Typography>
                    <Typography
                      fontWeight={600}
                      fontSize={22}
                      color={"#374145"}
                    >
                      {moveRequest?.delivery_details?.packaging
                        ?.packaging_payment
                        ? `$${moveRequest.delivery_details.packaging.packaging_payment}`
                        : "-"}
                    </Typography>
                  </Box>
                  <Box sx={{ ...priceInfoBox, backgroundColor: "#E9FFEF" }}>
                    <Typography
                      fontWeight={550}
                      fontSize={14}
                      color={"#374145"}
                    >
                      Confirmation Payment
                    </Typography>
                    <Typography
                      fontWeight={600}
                      fontSize={22}
                      color={"#374145"}
                    >
                      {moveRequest?.initial_deposit
                        ? `$${Math.round(moveRequest.initial_deposit)}`
                        : "-"}
                    </Typography>
                  </Box>
                </Stack>
                <Stack gap={1} p={1}>
                  <Box sx={{ ...priceInfoBox, backgroundColor: "#E4F2FF" }}>
                    <Typography
                      fontWeight={550}
                      fontSize={14}
                      color={"#374145"}
                    >
                      Total Cost of Move
                    </Typography>
                    <Typography
                      fontWeight={600}
                      fontSize={22}
                      color={"#374145"}
                    >
                      {moveRequest?.move_payment
                        ? `$${Math.ceil(moveRequest.move_payment)}`
                        : "-"}
                    </Typography>
                  </Box>
                  <Box sx={{ ...priceInfoBox, backgroundColor: "#F4FFDE" }}>
                    <Typography
                      fontWeight={550}
                      fontSize={14}
                      color={"#374145"}
                    >
                      Balance Owed to Carrier
                    </Typography>
                    <Typography
                      fontWeight={600}
                      fontSize={22}
                      color={"#374145"}
                    >
                      {moveRequest?.move_payment && moveRequest?.initial_deposit
                        ? `$${
                            moveRequest.move_payment -
                            moveRequest.initial_deposit
                          }`
                        : "-"}
                    </Typography>
                  </Box>
                </Stack>
              </Box>
            </Box>
            <Box mt={2}>
              <Typography
                fontWeight={550}
                fontSize={16}
                color={theme.palette.primary.main}
              >
                Inventory Information
              </Typography>
              <Button
                onClick={() => {
                  navigate(`/modifyItems/${moveRequest.canonical_id}`);
                }}
                sx={{ marginTop: 2 }}
                variant="outlined"
              >
                Edit or View Items
              </Button>
            </Box>
          </Grid>
        ) : (
          <></>
        )}

        {process.env.REACT_APP_TENANT === "wemove" ||
        process.env.REACT_APP_TENANT === "turbotenant" ||
        process.env.REACT_APP_TENANT === "sold" ? (
          <Grid
            item
            xs={12}
            mt={2}
            p={2}
            sx={{
              background: "#FFFFFF",
              borderTop: `1.5px solid ${theme.palette.primary.main}`,
              borderRadius: 2,
            }}
          >
            <Typography
              fontWeight={550}
              fontSize={16}
              color={theme.palette.primary.main}
            >
              Carrier Information
            </Typography>

            <Box
              mt={2}
              display={isMobileOrTab ? "block" : "flex"}
              justifyContent={"space-between"}
            >
              <Stack>
                <InputLabel
                  sx={{
                    fontSize: 14,
                  }}
                  htmlFor="carrierName"
                >
                  Carrier Name
                </InputLabel>

                <TextField
                  id="carrierName"
                  variant="outlined"
                  margin="dense"
                  size="small"
                  value={"WeMove Carrier Network"}
                  sx={{
                    width: 300,
                  }}
                />
              </Stack>

              <Stack>
                <InputLabel
                  sx={{
                    fontSize: 14,
                  }}
                  htmlFor="phoneNo"
                >
                  Phone No.
                </InputLabel>

                <TextField
                  id="phoneNo"
                  variant="outlined"
                  margin="dense"
                  size="small"
                  value={"754-249-2015"}
                  sx={{
                    width: 300,
                  }}
                />
              </Stack>

              <Stack>
                <InputLabel
                  sx={{
                    fontSize: 14,
                  }}
                  htmlFor="carrierAddress"
                >
                  Carrier Address
                </InputLabel>
                <TextField
                  id="carrierAddress"
                  variant="outlined"
                  margin="dense"
                  size="small"
                  value={"254 Chapman Road, Suite 209"}
                  sx={{
                    width: 300,
                  }}
                />
              </Stack>
            </Box>

            <Box
              mt={2}
              display={isMobileOrTab ? "block" : "flex"}
              justifyContent={"space-between"}
            >
              <Stack>
                <InputLabel
                  sx={{
                    fontSize: 14,
                  }}
                  htmlFor="carrierCity"
                >
                  Carrier City
                </InputLabel>

                <TextField
                  id="carrierCity"
                  variant="outlined"
                  margin="dense"
                  size="small"
                  value={"Newark"}
                  sx={{
                    width: 300,
                  }}
                />
              </Stack>

              <Stack>
                <InputLabel
                  sx={{
                    fontSize: 14,
                  }}
                  htmlFor="carrierState"
                >
                  Carrier State
                </InputLabel>

                <TextField
                  id="carrierState"
                  variant="outlined"
                  margin="dense"
                  size="small"
                  value={"DE"}
                  sx={{
                    width: 300,
                  }}
                />
              </Stack>

              <Stack>
                <InputLabel
                  sx={{
                    fontSize: 14,
                  }}
                  htmlFor="carrierZip"
                >
                  Carrier Zip
                </InputLabel>
                <TextField
                  id="carrierZip"
                  variant="outlined"
                  margin="dense"
                  size="small"
                  value={"19702"}
                  sx={{
                    width: 300,
                  }}
                />
              </Stack>
            </Box>
          </Grid>
        ) : (
          <></>
        )}
      </Grid>
    </>
  );
};

export default MoveRequestDetail;
