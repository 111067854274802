import {
  Box,
  Button,
  ButtonBase,
  Card,
  CardContent,
  CardMedia,
  Divider,
  Grid,
  Modal,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import WeMoveHeader from "../../components/header/weMoveHeader";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import TurboTenantLogo from "../../assets/logo/turbotenant_logo.png";
import MoveBeesLogo from "../../assets/logo/MoveBees-Logo-VR-Yellow.jpg";
import KruppLogo from "../../assets/logo/Krupp.png";
import addLine from "../../assets/images/Additional_Service.png";
import additionalService from "../../assets/images/Additional.png";
import card1 from "../../assets/images/Transport.png";
import card2 from "../../assets/images/Packing.png";
import card3 from "../../assets/images/Conceirge.png";
import card4 from "../../assets/images/Insurance.png";
import logoWeMove from "../../assets/logo/WeMoveNewLogo.png";
import SummitMovingLogo from "../../assets/logo/SummitMoving.png";
import { useEffect, useState } from "react";
import RediractionMessageModal from "../../components/modals/rediractionMessageModal";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  getMoveRequestById,
  updateMoveRequestById,
} from "../../redux/actions/move-request";
import { unwrapResult } from "@reduxjs/toolkit";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import WidgetsIcon from "@mui/icons-material/Widgets";
import GppGoodOutlinedIcon from "@mui/icons-material/GppGoodOutlined";
import Person4OutlinedIcon from "@mui/icons-material/Person4Outlined";
import ReactGA from "react-ga4";
import ContactSupport from "../../components/contactSupport";
import { NeedHelp } from "../../components/needHelp";
import LogoHeader from "../../components/logo-header/logoHeader";
import {
  getCarrierContact,
  getCarrierName,
} from "../../assets/utils/carrierContacts";

const howerButton = {
  width: "100%",
  display: "block",
  "&:hover .hover-text": {
    color: "#5858E0",
  },
  "&:hover .hover-background": {
    backgroundColor: "#FFFFFF",
    boxShadow: "10px 10px 5px -2px rgba(88, 88, 224, 0.3)",
  },
  "&:hover .icon-background": {
    backgroundColor: "#5858E0",
  },
  "&:hover .icon": {
    color: "#FFFFFF",
  },
};

const MoveSuccess = () => {
  const [url, setUrl] = useState(process.env.REACT_APP_URL || "");

  if (url) {
    ReactGA.send({
      hitType: "pageview",
      page: "/move-success/:moveRequestId",
      title: "MoveSuccess",
    });
  }

  const dispatch = useDispatch();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));

  const { moveRequestId } = useParams();
  const [moveRequest, setMoveRequest] = useState<any>(null);

  const [link, setLink] = useState("");
  const [isRedirectMessageModal, setIsRedirectMessageModal] = useState(false);

  const handleRediractionModal = (url: any) => {
    setLink(url);
    setIsRedirectMessageModal(true);
  };
  const [count, setCount] = useState(0);
  const fetchMoveRequestById = (moveRequestId: any) => {
    dispatch<any>(getMoveRequestById(moveRequestId))
      .then(unwrapResult)
      .then((thisMoveRequest: any) => {
        setMoveRequest(thisMoveRequest);
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (moveRequestId) {
      fetchMoveRequestById(moveRequestId);
    }
  }, [moveRequestId]);
  useEffect(() => {
    if (moveRequest !== null && count === 0) {
      setCount((counter) => counter + 1);
      updateThisMoveRequest(
        {
          contract_signed: true,
        },
        moveRequest.id
      );
    }
  }, [moveRequest]);
  const handleNavigation = () => {
    setFinishModal(true);
    const newWindow = window.open(
      `/ConsumerDashboard/${moveRequest.user.canonical_id}`,
      "_blank"
    );
    if (newWindow) {
      // The new window/tab was successfully created, now close the current window
      window.close();
    } else {
      // The new window/tab couldn't be created (possibly due to popup blockers)
      alert("Please allow popups for this website");
    }
  };
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [finishModal, setFinishModal] = useState(false);
  const updateThisMoveRequest = (body: any, moveReqId: number) => {
    setIsPageLoading(true);
    const params = {
      id: moveReqId,
      ...body,
    };
    dispatch<any>(updateMoveRequestById(params))
      .then(unwrapResult)
      .then((thisMoveRequest: any) => {
        setTimeout(() => {
          setIsPageLoading(false);
        }, 1300);
      })
      .catch((err: any) => {
        console.log(err);
        setTimeout(() => {
          setIsPageLoading(false);
        }, 1300);
      });
  };
  const currentTenant = process.env.REACT_APP_TENANT;
  return (
    <>
      <Modal
        open={finishModal}
        onClose={() => {
          setFinishModal(true);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "50%",
            bgcolor: "background.paper",
            borderRadius: "8px",
            boxShadow: 24,
            p: 3,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            color={"#5858E0"}
            fontSize={15}
            fontWeight={600}
            fontStyle={"italic"}
          >
            You have been redirected to your dashboard in a new window. Please
            close this window. Thanks.
          </Typography>
        </Box>
      </Modal>
      <LogoHeader />
      <Grid container>
        <Grid item xs={12} sm={8} md={8} marginX={"auto"}>
          <NeedHelp />

          <Box
            sx={{
              width: { xs: "95vw", sm: "85vw", md: "85vw", lg: "60vw" },
              height: { xs: "auto", sm: isMobile ? "90vh" : "auto" },
              p: { xs: 2, sm: 4 },
              mt: { xs: 3, sm: 5 },
              borderRadius: "8px",
              overflow: "auto",
              background: `linear-gradient(to bottom, ${theme.palette.primary.main} 50%, #FBFBFB 50%)`,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {/* Main Card */}
            <Card
              sx={{
                width: { xs: "90%", md: "50%" },
                textAlign: "center",
                borderRadius: 3,
                overflow: "hidden",
                boxShadow: 3,
              }}
            >
              <CardContent>
                {/* Success Icon */}
                <Box
                  sx={{
                    backgroundColor: "#E6F0F7",
                    borderRadius: "50%",
                    width: 60,
                    height: 60,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    margin: "auto",
                  }}
                >
                  <CheckCircleIcon color="primary" fontSize="large" />
                </Box>

                {/* Success Message */}
                <Typography
                  color={theme.palette.primary.main}
                  variant="h6"
                  fontWeight="bold"
                  sx={{ mt: 2 }}
                >
                  Your move has been booked successfully!
                </Typography>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  sx={{ mt: 1, mb: 2 }}
                >
                  {currentTenant === "justintimemoving" ? (
                    <>
                      Thank you for choosing JIT Moving for your upcoming move!
                      We appreciate you taking the time to complete your
                      inventory. Our team will be in touch shortly to discuss
                      your moving quote and answer any questions you may have.
                      We’re committed to making your move as seamless as
                      possible.
                      <br />
                      <strong>Best,</strong>
                      <br />
                      The JIT Moving Team
                    </>
                  ) : currentTenant === "quicknick-moving" ? (
                    <>
                      Thanks for updating your inventory! Our team is now
                      reviewing your details, and a moving specialist will be in
                      touch soon to provide your customized quote and answer any
                      questions you may have.
                      <br />
                      <strong>Best,</strong>
                      <br />
                      The Quick Nick Moving Team
                    </>
                  ) : currentTenant === "miraclemoversutah" ? (
                    <>
                      Your inventory has been received by Miracle Movers. A
                      moving consultant is reviewing it and will contact you
                      shortly. Hang tight while we work our magic for you.
                      <br />
                      <strong>Best,</strong>
                      <br />
                      The Miracle Sutah Moving Team
                    </>
                  ) :currentTenant === "conroymoving" ? (
                    <>
                      Thanks for submitting your inventory. One of our moving consultants will contact you shortly
                      <br />
                      <strong>Best,</strong>
                      <br />
                      The Conroy Moving Team
                  </>
                  ):currentTenant === "majesticmountainmovers" ? (
                    <>
                      Thanks for submitting your inventory a moving consultant will be reaching out to you shortly.
                      <br />
                      <strong>Best,</strong>
                      <br />
                      The Majestic Mountain Moving Team
                  </>
                  )  : currentTenant === "skitownmoving" ? (
                    <>
                      Thanks for submitting your inventory a moving consultant will reach out to you shortly.
                      <br />
                      <strong>Best,</strong>
                      <br />
                      The Skitown Moving Team
                  </>
                  ) : currentTenant === "centralcoastmoving" ? (
                    <>
                      Thank you for choosing Central Coast Moving & Storage. Let’s make your next move your BEST MOVE together!
                      <br />
                      <strong>Best,</strong>
                      <br />
                      The Central Coast Moving Team
                  </>
                  ): (
                    " Thank you so much for being a trusted Wemove customer. We will send move updates to your email and SMS."
                  )}
                </Typography>

                <Divider sx={{ mt: 3, mb: 2, backgroundColor: "#7A9AF7" }} />

                {/* Carrier Information */}
                <Box
                  display="flex"
                  justifyContent="space-between"
                  px={2}
                  mb={1}
                >
                  <Typography variant="body2" color="textSecondary">
                    Carrier Name:
                  </Typography>
                  <Typography variant="body2" fontWeight="bold">
                    {getCarrierName()}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between" px={2}>
                  <Typography variant="body2" color="textSecondary">
                    Contact:
                  </Typography>
                  <Typography variant="body2" fontWeight="bold">
                    {getCarrierContact()}
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Box>
          <Stack alignItems="center" alignSelf="center" position="relative">
            {/* Upper Image - Background */}
            <img
              src={addLine}
              alt=""
              width="90%"
              height={10}
              style={{ position: "relative" }} // Keep it in the normal flow
            />

            {/* Lower Image - Centered on top of the upper image */}
            <img
              src={additionalService}
              width={130}
              height={50}
              alt=""
              style={{
                position: "absolute",
                top: "50%", // Center vertically
                left: "50%", // Center horizontally
                transform: "translate(-50%, -50%)", // Adjust position to be centered
              }}
            />
          </Stack>

          <Box p={1}>
            {/* <Typography fontSize={12} fontWeight={400} mt={2} mb={2}>
              Thanks so much for being a trusted WeMove customer. We look
              forward to making your move and transition as seamless as
              possible. Please keep an eye out for moving updates and tips via
              email and SMS. If you need anything along your journey, please
              don’t hesitate to reach out. You will be receiving additional
              information along the way.
            </Typography> */}
            <Stack
              my={5}
              alignItems={"center"}
              justifyContent={"space-around"}
              direction={isMobile ? "column" : "row"}
              spacing={2}
            >
              <Card
                sx={{
                  textAlign: "center",
                  boxShadow: 3,
                  borderRadius: 2,
                  padding: 2,
                  width: 200,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Stack
                  p={1}
                  sx={{
                    backgroundColor: "#53AEE62E",
                    width: 50,
                    borderRadius: 4,
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                  }}
                >
                  <CardMedia
                    component="img"
                    image={card1} // Replace with actual path
                    alt="Auto Transport"
                    sx={{
                      width: 50,
                      height: 50,
                      margin: "0 auto",
                    }}
                  />
                </Stack>
                <CardContent>
                  <Typography fontWeight="bold" gutterBottom>
                    Auto Transport
                  </Typography>
                  <Typography variant="body2" color="textSecondary" mb={2}>
                    Need to ship a vehicle?
                  </Typography>
                  <Button
                    onClick={() =>
                      handleRediractionModal(
                        "https://wemove.ai/auto-transport/"
                      )
                    }
                    variant="contained"
                    color="primary"
                  >
                    Get now
                  </Button>
                </CardContent>
              </Card>
              <Card
                sx={{
                  textAlign: "center",
                  boxShadow: 3,
                  borderRadius: 2,
                  padding: 2,
                  width: 200,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Stack
                  p={1}
                  sx={{
                    backgroundColor: "#EBB28733",
                    width: 50,
                    borderRadius: 4,
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                  }}
                >
                  <CardMedia
                    component="img"
                    image={card2} // Replace with actual path
                    alt="Packing Supplies"
                    sx={{
                      width: 50,
                      height: 50,
                      borderRadius: "50%",
                    }}
                  />
                </Stack>
                <CardContent sx={{ textAlign: "center" }}>
                  <Typography fontWeight="bold" gutterBottom>
                    Packing Supplies
                  </Typography>
                  <Typography variant="body2" color="textSecondary" mb={2}>
                    Need packing supplies?
                  </Typography>
                  <Button
                    onClick={() => {
                      handleRediractionModal(
                        "https://shareasale.com/r.cfm?b=883129&u=4219278&m=66601&urllink=&afftrack="
                      );
                    }}
                    variant="contained"
                    color="primary"
                  >
                    Get now
                  </Button>
                </CardContent>
              </Card>
            </Stack>
            <Stack
              my={2}
              alignItems={"center"}
              justifyContent={"space-around"}
              direction={isMobile ? "column" : "row"}
              spacing={2}
            >
              <Card
                sx={{
                  textAlign: "center",
                  boxShadow: 3,
                  borderRadius: 2,
                  padding: 2,
                  width: 200,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Stack
                  p={1}
                  sx={{
                    backgroundColor: "#22A0950D",
                    width: 50,
                    borderRadius: 4,
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                  }}
                >
                  <CardMedia
                    component="img"
                    image={card4} // Replace with actual path
                    alt="Premium Insurance"
                    sx={{
                      width: 50,
                      height: 50,
                      margin: "0 auto",
                      borderRadius: "50%",
                    }}
                  />
                </Stack>
                <CardContent>
                  <Typography fontWeight="bold" gutterBottom>
                    Premium Insurance
                  </Typography>
                  <Typography variant="body2" color="textSecondary" mb={2}>
                    Need additional insurance coverage?
                  </Typography>
                  <Button
                    onClick={() =>
                      handleRediractionModal(
                        "https://www.movinginsurance.com/WeMove.asp"
                      )
                    }
                    variant="contained"
                    color="primary"
                  >
                    Get now
                  </Button>
                </CardContent>
              </Card>
              <Card
                sx={{
                  textAlign: "center",
                  boxShadow: 3,
                  borderRadius: 2,
                  padding: 2,
                  width: 200,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Stack
                  p={1}
                  sx={{
                    backgroundColor: "#DD33301A",
                    width: 50,
                    borderRadius: 4,
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                  }}
                >
                  <CardMedia
                    component="img"
                    image={card3} // Replace with actual path
                    alt="Premium Insurance"
                    sx={{
                      width: 50,
                      height: 50,
                      margin: "0 auto",
                      borderRadius: "50%",
                    }}
                  />
                </Stack>
                <CardContent>
                  <Typography fontWeight="bold" gutterBottom>
                    Concierge Service
                  </Typography>
                  <Typography variant="body2" color="textSecondary" mb={2}>
                    Want a free moving concierge?
                  </Typography>
                  <Button
                    onClick={() =>
                      handleRediractionModal("https://wemoveai.moveeasy.com/")
                    }
                    variant="contained"
                    color="primary"
                  >
                    Get now
                  </Button>
                </CardContent>
              </Card>
            </Stack>

            <Divider variant="middle" />
          </Box>

          <Stack p={3} alignItems="center">
            <Button
              onClick={() => {
                // navigate(`/ConsumerDashboard/${moveRequest.user.id}`);
                handleNavigation();
              }}
              sx={{
                width: 180,
                height: 45,
                backgroundColor: ` ${theme.palette.primary.main} !important`,
                color: "#FFFFFF",
                fontSize: 12,
                fontWeight: 550,
                borderRadius: 4,
              }}
            >
              Continue
            </Button>
          </Stack>
          <Stack
            m={3}
            alignSelf={"center"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            {/* <ContactSupport /> */}
          </Stack>
        </Grid>
      </Grid>

      {isRedirectMessageModal && (
        <RediractionMessageModal
          isRedirectMessageModal={isRedirectMessageModal}
          setIsRedirectMessageModal={setIsRedirectMessageModal}
          link={link}
        />
      )}
    </>
  );
};

export default MoveSuccess;
