import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MailIcon from "@mui/icons-material/Mail";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { Button, Stack } from "@mui/material";
import logoCube from "../../assets/logo/Group 15.png";
import logoWeMove from "../../assets/logo/WEMOVE.png";
import GridViewIcon from "@mui/icons-material/GridView";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ConsumerDashboard from "../consumerDashboard/consumerDashboard";
import LogoutIcon from "@mui/icons-material/Logout";
import TurboTenantLogo from "../../assets/logo/turbotenant_logo.png"
import ReactGA from 'react-ga4'
import MoveBeesLogo from "../../assets/logo/MoveBees-Logo-VR-Yellow.jpg"
import KruppLogo from "../../assets/logo/Krupp.png"
import SummitMovingLogo from "../../assets/logo/SummitMoving.png"

const drawerWidth = 240;

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * Remove this when copying and pasting into your project.
   */
  window?: () => Window;
}

export default function AppDrawer(props: Props) {
  const currentTenant = process.env.REACT_APP_TENANT || "";
  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);

  const { consumerCanonicalId } = useParams();

  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };
  const navigate = useNavigate();
  const handleLogout = () => {
    // Implement your logout logic here, e.g., clearing session storage, redirecting to login page
    localStorage.removeItem('jwtToken')
    localStorage.removeItem('role')
    localStorage.removeItem('user-object')
    localStorage.removeItem("sessionId");
    localStorage.removeItem("flowStartedOn");
    localStorage.removeItem('action-type')
    localStorage.removeItem('current-move-request');
    localStorage.removeItem('user-origin');
    localStorage.removeItem('consumerPhoneNo');
    navigate("/login"); // Redirect to login page
  };

 

  
    ReactGA.send({
      hitType:'pageview',
      page:"/start-move",
      title:"Start Move started"
    })

  

  const drawer = (
    <div>
      <Stack
        alignItems={"center"}
        justifyContent={"center"}
        direction={"row"}
        // mt={3}
        // marginBottom={2}
      >
        { currentTenant === "wemove" || currentTenant ==='sold'? (
          <>
            <img src={logoCube} width={30} height={30} alt="" />
            <img src={logoWeMove} width={110} height={18} alt="" />
          </>
        ) : currentTenant === "turbotenant" ? (
            <img src={TurboTenantLogo} width={150} height={44} alt="" />
        ) : currentTenant === 'movebees'? (<img src={MoveBeesLogo} width={240} height={66} alt="" /> ) 
        : currentTenant === 'summitmoving'? (<img src={SummitMovingLogo} width={150} height={60} alt="" /> ) 
        : currentTenant === 'krupp'? (<img src={KruppLogo} width={150} height={44} alt="" /> ) 
        :(<></>)
        }
      </Stack>
      {/* <Toolbar /> */}
      {/* <Divider /> */}
      {/* <List>
        {[
          "Dashboard",
          // , "Starred", "Send email", "Drafts"
        ].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton>
              <ListItemIcon>
                {index % 2 === 0 ? <GridViewIcon /> : <MailIcon />}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List> */}
      <Divider />
      <List >
        <ListItem disablePadding>
          {localStorage.getItem("user-origin") && localStorage.getItem('user-object') && (
            <ListItemButton onClick={handleLogout}>
              <ListItemIcon>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText primary="Logout" />
            </ListItemButton>
          )}
        </ListItem>
      </List>
      {/* <List>
        {["All mail", "Trash", "Spam"].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton>
              <ListItemIcon>
                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List> */}
    </div>
  );

  // Remove this const when copying and pasting into your project.
  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex", width:'100%', background: "#FFFFFF" }}>
{localStorage.getItem("user-origin") && localStorage.getItem('user-object') && (
            <Button onClick={handleLogout}>
              Logout
            </Button>
             
          )}      
        <ConsumerDashboard consumerCanonicalId={consumerCanonicalId} />
    </Box>
  );
}
