import {
  Box,
  Button,
  Grid,
  LinearProgress,
  Stack,
  TextField,
  Typography,
  Alert,
  useTheme,
  useMediaQuery,
  IconButton,
  Divider,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import CancelIcon from "@mui/icons-material/Cancel";
import { autoRedirectToInventory } from "../../redux/actions/move-request";
import { unwrapResult } from "@reduxjs/toolkit";
import Backdrop from "@mui/material/Backdrop";
import * as AWS from "aws-sdk";
import {
  fetchInventoryByPhone,
  fetchInventoryFromImage,
} from "../../redux/actions/room-details";
import UploadCompletedModal from "../../components/modals/uploadCompletedModal";
import imageCompression from "browser-image-compression";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import ContactSupport from "../../components/contactSupport";
import LogoHeader from "../../components/logo-header/logoHeader";
import { v4 as uuidv4 } from "uuid";

interface PayloadObject {
  title: string;
  images: File[]; // Specify the type as File[]
}
const AddInventory = () => {
  const { phoneNumber } = useParams<{ phoneNumber: string }>();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  // const [jwt, setJwt] = useState<any>("")
  const [isLoading, setIsLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("");
  const [uploadCompletedModalVisible, setUploadCompletedModalVisible] =
    useState(false);
  const [imagePreviews, setImagePreviews] = useState<string[][]>([]);
  const [payloadObjects, setPayloadObjects] = useState<PayloadObject[]>([
    { title: "", images: [] },
  ]);
  const [formError, setFormError] = useState("");
  const [sessionId, setSessionId] = useState(uuidv4());

  useEffect(() => {
    setIsLoading(false);
    const body = {
      phone: phoneNumber,
    };
    const { phone } = body;
    if (phoneNumber === phone) {
      dispatch<any>(autoRedirectToInventory(body))
        .then(unwrapResult)
        .then((res: any) => {})
        .catch((err: any) => {
          console.log(err);
        });
    }
  }, []);
  const handleStartNewSession = () => {
    setSessionId(uuidv4()); // Generate a new session ID
  };
  const handleUploadImages = async () => {
    if (
      payloadObjects.every(
        (payload) =>
          payload.title &&
          payload.images.length &&
          payload.images.every((img) => img.type)
      )
    ) {
      setIsLoading(true);
      setLoadingMessage("Uploading image(s). Please wait...");

      let totalImages = payloadObjects.reduce(
        (acc, payload) => acc + payload.images.length,
        0
      );
      let currentImageCount = 0;

      for (let i = 0; i < payloadObjects.length; i++) {
        for (let j = 0; j < payloadObjects[i].images.length; j++) {
          const formData = new FormData();
          formData.append("phoneNumber", phoneNumber || "");
          const roomsArray = [{ roomName: payloadObjects[i].title }];
          formData.append("rooms", JSON.stringify(roomsArray));
          formData.append("file", payloadObjects[i].images[j]);

          formData.append("currentCount", String(currentImageCount + 1));
          formData.append("totalCount", String(totalImages));
          formData.append("sessionId", sessionId);

          try {
            const response = await dispatch<any>(
              fetchInventoryFromImage(formData)
            );
            unwrapResult(response);

            currentImageCount += 1;
          } catch (error) {
            console.error("Error uploading image:", error);
            setLoadingMessage("Error processing some images.");
          }
        }
      }
      setLoadingMessage("All images uploaded successfully!");
      setIsLoading(false);
      navigate("/success-message");
    } else {
      setFormError(
        "Please ensure each room has a name and at least one valid image."
      );
    }
  };

  // Function to handle title change
  const handleTitleChange1 = (index: any, value: any) => {
    setFormError("");
    const updatedPayloadObjects = [...payloadObjects];
    updatedPayloadObjects[index].title = value;
    setPayloadObjects(updatedPayloadObjects);
  };
  const addPayloadObject = () => {
    setPayloadObjects([...payloadObjects, { title: "", images: [] }]);
  };
  // Function to handle adding an image to a payload object
  const addImageToPayload = async (index: any, image: any, imageIndex: any) => {
    setFormError("");
    setIsLoading(true);
    setLoadingMessage("Compressing Image...");
    const updatedPayloadObjects: PayloadObject[] = [...payloadObjects]; // Specify the type of updatedPayloadObjects
    const file: File = image.target.files[0]; // Specify the type of file

    const previewUrl = URL.createObjectURL(file);

    // Options for image compression
    const options = {
      maxSizeMB: 3,
      maxWidthOrHeight: 2000,
      useWebWorker: true,
    };

    // Compress the image file
    const compressedFile = await imageCompression(file, options);
    if (compressedFile) {
      // Update the payload with the compressed file
      updatedPayloadObjects[index].images[imageIndex] = compressedFile;
      setPayloadObjects(updatedPayloadObjects);
    } else {
      updatedPayloadObjects[index].images[imageIndex] = file;
      setPayloadObjects(updatedPayloadObjects);
    }

    const updatedPreviews = [...imagePreviews];
    if (!updatedPreviews[index]) updatedPreviews[index] = [];
    updatedPreviews[index][imageIndex] = previewUrl;
    setImagePreviews(updatedPreviews);

    setIsLoading(false);
    setLoadingMessage("");
  };
  const addImageInput = (index: number) => {
    const updatedPayloadObjects = [...payloadObjects];
    if (
      updatedPayloadObjects[index].images.length === 0 ||
      updatedPayloadObjects[index].images.slice(-1)[0].type !== undefined
    ) {
      updatedPayloadObjects[index].images.push(new File([], ""));
      setPayloadObjects(updatedPayloadObjects);
    }
  };
  const discardRoom = (index: number) => {
    const updatedPayloadObjects = [...payloadObjects];
    updatedPayloadObjects.splice(index, 1);
    setPayloadObjects(updatedPayloadObjects);
  };

  const discardImage = (roomIndex: number, imageIndex: number) => {
    const updatedPayloadObjects = [...payloadObjects];

    if (
      updatedPayloadObjects[roomIndex] &&
      updatedPayloadObjects[roomIndex].images[imageIndex]
    ) {
      const updatedPreviews = [...imagePreviews];

      // Clean up the preview URL
      if (
        updatedPreviews[roomIndex] &&
        updatedPreviews[roomIndex][imageIndex]
      ) {
        URL.revokeObjectURL(updatedPreviews[roomIndex][imageIndex]);
        updatedPreviews[roomIndex].splice(imageIndex, 1);
        setImagePreviews(updatedPreviews);
      }

      updatedPayloadObjects[roomIndex].images.splice(imageIndex, 1);
      setPayloadObjects(updatedPayloadObjects);
    }
  };

  return (
    <>
      <LogoHeader />
      <Grid container marginX={"auto"}>
        <Grid item xs={12} md={8} marginX={"auto"}>
          {isLoading && (
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={isLoading}
              onClick={() => {
                setIsLoading(true);
              }}
            >
              <Box sx={{ width: "50%" }}>
                <LinearProgress />
                <Typography
                  fontSize={16}
                  fontWeight={500}
                  textAlign="center"
                  mt={1}
                >
                  {loadingMessage &&
                    loadingMessage.split("\n").map((line, index) => (
                      <Typography key={index} component="span" display="block">
                        {line}
                      </Typography>
                    ))}
                </Typography>
              </Box>
            </Backdrop>
          )}

          {uploadCompletedModalVisible && (
            <UploadCompletedModal
              setUploadCompletedModalVisible={setUploadCompletedModalVisible}
              uploadCompletedModalVisible={uploadCompletedModalVisible}
            />
          )}
          {process.env.REACT_APP_TENANT === "wemove" ||
            (process.env.REACT_APP_TENANT === "turbotenant" && (
              <Stack
                mt={3}
                alignSelf={"center"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <ContactSupport />
              </Stack>
            ))}
          <Box
            sx={{
              width: { xs: "90vw", sm: "80vw", md: "70vw", lg: "60vw" },
              height: isMobile ? "90vh" : "auto",
              p: { xs: 2, sm: 4 },
              mt: { xs: 3, sm: 5 },
              border: "1px solid #0000001A",
              borderRadius: "8px",
              overflow: "auto",
              backgroundColor: "#FBFBFB",
            }}
          >
            <Stack
              sx={{
                alignItems: isMobile ? "center" : "flex-start",
                overflow: "auto",
                mb: 3, // Margin bottom for spacing
              }}
            >
              <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
                width={"100%"}
                sx={{ mb: 2 }}
              >
                <Stack>
                  <Typography fontSize={isMobile ? 18 : 24} fontWeight={500}>
                    Upload Photos
                  </Typography>
                  <Typography fontSize={isMobile ? 10 : 16} color={"#646464"}>
                    Our AI will analyse your photos of each room,
                    <Typography fontSize={isMobile ? 10 : 16} color={"#646464"}>
                      garage, patio etc in one click
                    </Typography>
                  </Typography>
                </Stack>
              </Stack>
            </Stack>

            <Stack
              sx={{
                height: { xs: "auto", sm: "auto", md: "auto" },
                p: { xs: 2, sm: 4 },
                mt: { xs: 3, sm: 5 },
                border: "1px solid #0000001A",
                borderRadius: "8px",
                overflow: "auto",
                backgroundColor: "#FFFFFF",
              }}
              spacing={2}
              mt={2}
              justifyContent={"center"}
            >
              {payloadObjects.map((payload, payloadIndex) => (
                <>
                  <Stack
                    alignItems={"center"}
                    spacing={2}
                    key={payloadIndex}
                    width="100%"
                    marginY={5}
                  >
                    {payloadIndex === payloadObjects.length - 1 && (
                      <Button
                        disabled={
                          payloadObjects.length > 0 &&
                          (payload.title === "" ||
                            payload.images.some((image) => image.type === ""))
                        }
                        sx={{
                          width: { xs: "100%", sm: 180 },
                          backgroundColor: "#FFD41D",
                          borderRadius: 12,
                          alignSelf: { xs: "center", sm: "flex-start" },
                          marginBottom: { xs: 2, sm: 0 },
                        }}
                        variant="contained"
                        onClick={addPayloadObject}
                      >
                        <Typography color={"#000000"} fontSize={12}>
                          + Add more rooms
                        </Typography>
                      </Button>
                    )}
                    <Stack
                      justifyContent={"space-between"}
                      alignItems={"center"}
                      width={"100%"}
                      direction={isMobile ? "column" : "row"}
                      spacing={1}
                    >
                      <TextField
                        variant="outlined"
                        type="text"
                        label="Enter Room Name"
                        size="small"
                        sx={{
                          backgroundColor: "#FBFBFB",
                          fontSize: { xs: 14, sm: 16 },
                          width: "50%",
                        }}
                        value={payload.title}
                        onChange={(e) =>
                          handleTitleChange1(payloadIndex, e.target.value)
                        }
                      />
                      {payloadIndex !== 0 && (
                        <Button
                          sx={{
                            width: 156,
                            height: 50,
                            borderRadius: 12,
                            backgroundColor: "#FFE3E3 !important",
                          }}
                          variant="contained"
                          color="error"
                          onClick={() => discardRoom(payloadIndex)}
                        >
                          <Typography color={"#FF3232"} fontSize={12}>
                            Delete Room
                          </Typography>
                        </Button>
                      )}
                    </Stack>
                    {payloadObjects.length > 0 &&
                    (payload.title === "" ||
                      payload.images.some((image) => image.type === "")) ? (
                      <Stack
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                        spacing={2}
                        sx={{
                          padding: 2,
                          border: "2px dashed #0257CF",
                          borderRadius: "8px",
                          backgroundColor: "#E4F0FF6B",
                          height: 150,
                          width: "95%",
                        }}
                      >
                        <Typography
                          fontWeight={600}
                          fontSize={{ xs: 14, sm: 16 }}
                        >
                          Add Images
                        </Typography>
                        <Typography fontSize={{ xs: 10, sm: 12 }}>
                          Add 2-4 room photos to capture all large furniture
                        </Typography>
                      </Stack>
                    ) : (
                      <></>
                    )}

                    <Stack
                      direction="row"
                      flexWrap="wrap"
                      alignItems="center"
                      spacing={2}
                      sx={{
                        width: "100%",
                        gap: 2,
                      }}
                    >
                      {payload.images.map((image: any, imageIndex) => (
                        <Stack
                          spacing={1}
                          alignItems="flex-start"
                          key={imageIndex}
                        >
                          {image.size === 0 && (
                            <Stack
                              direction="row"
                              spacing={1}
                              alignItems="center"
                            >
                              <IconButton
                                color="primary"
                                component="label"
                                style={{ display: "block", margin: "10px 0" }}
                              >
                                <Stack alignItems={"center"}>
                                  <AddAPhotoIcon />
                                  <input
                                    type="file"
                                    capture="environment"
                                    onChange={(e) =>
                                      addImageToPayload(
                                        payloadIndex,
                                        e,
                                        imageIndex
                                      )
                                    }
                                    accept="image/*"
                                    style={{ display: "none" }}
                                  />
                                  <Typography variant="caption">
                                    Take Photo
                                  </Typography>
                                </Stack>
                              </IconButton>

                              <IconButton
                                color="secondary"
                                component="label"
                                style={{ display: "block", margin: "10px 0" }}
                              >
                                <Stack alignItems={"center"}>
                                  <FileUploadIcon />
                                  <input
                                    type="file"
                                    onChange={(e) =>
                                      addImageToPayload(
                                        payloadIndex,
                                        e,
                                        imageIndex
                                      )
                                    }
                                    accept="image/*"
                                    style={{ display: "none" }}
                                  />
                                  <Typography variant="caption">
                                    Upload
                                  </Typography>
                                </Stack>
                              </IconButton>
                              {payload.images.length > 0 &&
                                payload.images[payload.images.length - 1] &&
                                payload.images[payload.images.length - 1]
                                  .type === "" && (
                                  <IconButton
                                    onClick={() =>
                                      discardImage(
                                        payloadIndex,
                                        payload.images.length - 1
                                      )
                                    }
                                    sx={{
                                      display: "flex",
                                      flexDirection: "column",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      padding: 1,
                                      // backgroundColor: "#ffebee",
                                      borderRadius: "8px",
                                      "&:hover": {
                                        backgroundColor: "#ffcdd2",
                                      },
                                    }}
                                    size="small"
                                  >
                                    <CancelIcon fontSize="small" />
                                    <Typography variant="caption">
                                      Cancel
                                    </Typography>
                                  </IconButton>
                                )}
                            </Stack>
                          )}

                          <Box
                            sx={{
                              position: "relative",
                              width: { xs: 60, sm: 124 },
                              maxHeight: { xs: 60, sm: 124 },
                              borderRadius: 2,
                              overflow: "hidden",
                            }}
                          >
                            {imagePreviews[payloadIndex] &&
                              imagePreviews[payloadIndex][imageIndex] && (
                                <Box
                                  sx={{
                                    position: "relative",
                                    width: { xs: 60, sm: 124 },
                                    maxHeight: { xs: 60, sm: 124 },
                                    borderRadius: 2,
                                    overflow: "hidden",
                                  }}
                                >
                                  <img
                                    src={
                                      imagePreviews[payloadIndex][imageIndex]
                                    }
                                    alt={`Preview ${imageIndex + 1}`}
                                    style={{
                                      width: "100%",
                                      height: "100%",
                                      objectFit: "cover",
                                      borderRadius: 12,
                                    }}
                                  />

                                  <IconButton
                                    onClick={() =>
                                      discardImage(payloadIndex, imageIndex)
                                    }
                                    sx={{
                                      position: "absolute",
                                      top: 4,
                                      right: 4,
                                      backgroundColor:
                                        "rgba(255, 255, 255, 0.8)",
                                      color: "black",
                                      zIndex: 1,
                                      "&:hover": {
                                        backgroundColor:
                                          "rgba(255, 255, 255, 1)",
                                      },
                                    }}
                                    size="small"
                                  >
                                    <CancelIcon fontSize="small" />
                                  </IconButton>
                                </Box>
                              )}
                          </Box>
                        </Stack>
                      ))}
                    </Stack>

                    <Button
                      sx={{
                        width: 232,
                        borderRadius: 4,
                        height: 48,
                        marginBottom: 5,
                      }}
                      variant="contained"
                      onClick={() => addImageInput(payloadIndex)}
                      disabled={
                        payload.images.length > 0 &&
                        payload.images[payload.images.length - 1].type === ""
                      }
                    >
                      Add Image +
                    </Button>
                  </Stack>
                  <Divider />
                </>
              ))}
              {formError && <Alert severity="error">{formError}</Alert>}

              <Button
                onClick={() => {
                  handleUploadImages();
                }}
                variant="contained"
                sx={{
                  width: 180,
                  height: 45,
                  backgroundColor: `${theme.palette.primary.main} !important`,
                  color: "#FFFFFF",
                  fontSize: 12,
                  fontWeight: 550,
                  alignSelf: "center",
                  borderRadius: 14,
                }}
              >
                Continue
              </Button>
            </Stack>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default AddInventory;
